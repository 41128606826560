$brk-base-colors: ();
$brk-base-colors: map-merge((
				"brand-primary":      #2775FF,
				"secondary":          #3e4095,
				"white":              #fff,
				"black":              #000,
				"brk-dark-base":      #272727,
				"brk-base-1":         #69adff,
				"brk-base-2":         #00c6ff,
				"brk-base-3":         #0F5AE0,
				"brk-base-4":         #e8ecff,
				"brk-base-5":         #00f6ff,
				"brk-base-6":         #0B98F6,
				"brk-base-7":         #a7ebff,
				"brk-base-8":         #1600ba,// TODO: !!!
				"brk-secondary-1":    #4f34c9,
				"brk-secondary-2":    #4F00BA,
				"brk-secondary-3":    #7400ba,
				"brk-secondary-4":    #6e50c2,
				"brk-secondary-5":    #6377DF,
				"brk-secondary-6":    #b50aff,
				"text-base-color-1":  #8a8a8a,
				"text-base-color-2":  #0F5AE0,
				"text-base-color-3":  #f3f3f3,
        "form-color-1":				#e8ecff
), $brk-base-colors);

@function safe-rgb($color) {
	@return unquote("#{red($color)}, #{green($color)}, #{blue($color)}");
}

/* ----------- Palette variable ----------- */
:root {
	@each $color, $value in $brk-base-colors {
		--#{$color}: #{$value};
	}

	// RGB
	@each $color, $value in $brk-base-colors {
		--#{$color}-rgb: #{safe-rgb($value)};
  }
  
  --b-radius: inherit;

}
/* ----------- Palette variable ----------- */

/* ------------ Customs styles ------------ */
.brk-pricing-light__stick span {
	background-color: #68dcfe !important;
}
/* ----------- /Customs styles ------------ */